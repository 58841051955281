import styled from 'styled-components'
import { zIndex } from '../../styles/vars/layout.style'
import backgroundNoise from '../../images/noise-small.png'

export const TransitionMaskWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${zIndex.pageTransitionMask};
`

export const TransitionMaskInner = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background-color: ${({ color }) => color};
  background-image: url(${backgroundNoise});
`
