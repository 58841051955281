import { css } from 'styled-components'
import { font } from '../vars/font.style'
import { colors } from '../vars/colors.style'

export const typography = css`
  html {
    font-size: 62.5%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
  }

  body {
    font-size: 1.8rem;
    font-family: ${font.primary};
    font-style: normal;
    font-weight: ${font.weight.regular};
    line-height: 1.5;
    color: ${colors.dark};
  }

  strong {
    font-weight: ${font.weight.bold};
  }

  /* Links */
  a {
    color: currentColor;
    text-decoration: none;
    border-bottom: 0.1rem solid;
  }

  /* Headings - * 0.625 for vw @ 1600 */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    font-family: ${font.primary};
    font-style: normal;
    font-weight: ${font.weight.bold};
  }

  /* Forms */
  input,
  button {
    font-family: inherit;
    font-size: inherit;
  }

  /* Selection */
  ::-moz-selection {
    background: ${colors.dark};
    color: ${colors.light};
    text-shadow: none;
  }

  ::selection {
    background: ${colors.dark};
    color: ${colors.light};
    text-shadow: none;
  }

  /* Focus */
  *:focus {
    outline: none;

    .keyboard-in-use & {
      outline: grey auto 4px;
      outline-style: solid;
    }
  }
`
