import { createGlobalStyle } from 'styled-components'
import { reset } from './partials/reset.style'
import { typography } from './partials/typography.style'
import { locomotiveScrollStyles } from './partials/locomotiveScroll.style'
import { gatsbyTransitionLinkStyles } from './partials/gatsbyTransitionLink.style'
import { colors } from './vars/colors.style'
import { zIndex } from './vars/layout.style'
import { font } from './vars/font.style'

export const GlobalStyles = createGlobalStyle`
  ${reset}
  ${typography}
  ${locomotiveScrollStyles}
  ${gatsbyTransitionLinkStyles}

  #gatsby-focus-wrapper {
    font-size: 0px;
  }

  body {
    overscroll-behavior-y: none;
    background-color: ${colors.light};

    &.disable-scroll {
      overflow: hidden;
    }
  }

  #portal {
    position: relative;
    z-index: ${zIndex.portal};
  }

  h1,h2,h3,h4,h5,h6,p,a {
    font-weight: ${font.weight.regular};
    white-space: initial;
  }

  button {
    :hover {
      cursor: pointer;
    }

    :active {
      color: currentColor;
    }
  }
`
