import styled from 'styled-components'
import { colors } from '../../styles/vars/colors.style'
import { mq } from '../../styles/vars/media-queries.style'
import { zIndex } from '../../styles/vars/layout.style'
import { font } from '../../styles/vars/font.style'
import { easings } from '../../styles/vars/easings.style'

export const CookieBarOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: ${zIndex.cookieBar};
  pointer-events: ${({ show }) => (show ? undefined : 'none')};
  opacity: ${({ show }) => (show ? 1 : 0)};
  transition: opacity 0.3s ease 0.15s;
`

export const CookieBarMain = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transform: ${({ show }) => (show ? 'translateY(0)' : 'translateY(-100%)')};
  padding: 2.4rem 4rem;
  color: ${colors.light};
  background-color: ${colors.dark};
  transition: transform 0.2s ${easings.in.cubic};

  ${mq.tabletP} {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

export const CookieBarText = styled.div`
  font-size: 1.4rem;
  font-weight: ${font.weight.light};
  line-height: 1.5;

  ${mq.tabletP} {
    flex: 1;
    padding-right: 2em;
  }

  a {
    text-decoration: underline;
  }
`

export const CookieBarFooter = styled.div`
  display: flex;
  align-items: center;

  ${mq.belowTabletP} {
    margin-top: 2.2rem;
  }
`

export const CookieBarBtn = styled.button`
  cursor: pointer;
  padding: 0.7rem 2rem 0.1rem;
  font-family: ${font.secondary};
  font-variation-settings: ${font.weight.secondary.bold};
  line-height: 1;
  font-size: 3.2rem;
  color: ${colors.light};
  text-transform: uppercase;
  background-color: transparent;
  border: 0.1rem solid ${colors.light};

  ${mq.tabletP} {
    order: 1;
  }
`

export const CookieBarPageLink = styled.div`
  font-family: ${font.secondary};
  font-variation-settings: ${font.weight.secondary.bold};
  line-height: 1;
  font-size: 1.8rem;
  color: ${colors.light};
  text-transform: uppercase;

  ${mq.belowTabletP} {
    margin-left: 2.2rem;
  }

  ${mq.tabletP} {
    order: 0;
    margin-right: 1em;
  }
`
