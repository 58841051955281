import React from 'react'

const IconFacebook = ({
  width = 7,
  height = 15,
  fill = 'currentColor',
  responsive = false,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 7 15"
      aria-hidden="true"
      focusable="false"
      {...svgAttributes}
    >
      <path
        fill={fill}
        d="M5.09 0C3.335 0 1.91 1.599 1.91 3.571v1.786H0v2.857h1.91V15h2.545V8.214h2.227L7 5.357H4.455V3.93c0-.79.57-1.429 1.272-1.429H7V.115A16.975 16.975 0 005.09 0z"
      />
    </svg>
  )
}

export default IconFacebook
