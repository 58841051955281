import styled, { css } from 'styled-components'
import { easings } from '../../styles/vars/easings.style'

export const SlideMaskMain = styled.span`
  position: relative;
  display: block;
  overflow: hidden;
`

const SlideMaskChild = css`
  display: block;
  will-change: transform;
  transition: transform 0.4s ${easings.inOut.cubic};

  &:nth-child(2) {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
  }
`

export const SlideMaskChildVertical = styled.span`
  ${SlideMaskChild}

  &:nth-child(2) {
    transform: translateY(110%);
  }

  a:hover &,
  button:hover & {
    &:nth-child(1) {
      transform: translateY(-110%);
    }

    &:nth-child(2) {
      transform: translateY(0);
    }
  }
`

export const SlideMaskChildHorizontal = styled.span`
  ${SlideMaskChild}

  &:nth-child(2) {
    transform: translateX(-110%);
  }

  a:hover &,
  button:hover & {
    &:nth-child(1) {
      transform: translateX(110%);
    }

    &:nth-child(2) {
      transform: translateX(0);
    }
  }
`

export const SlideMaskChildHorizontalReverse = styled.span`
  ${SlideMaskChild}

  &:nth-child(2) {
    transform: translateX(110%);
  }

  a:hover &,
  button:hover & {
    &:nth-child(1) {
      transform: translateX(-110%);
    }

    &:nth-child(2) {
      transform: translateX(0);
    }
  }
`
