import React from 'react'

const IconArrowheadLeft = ({
  width = 51,
  height = 33,
  fill = 'currentColor',
  responsive = false,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 51 33"
      aria-hidden="true"
      focusable="false"
      {...svgAttributes}
    >
      <path
        fill={fill}
        d="M18.215 0H21.439C21.439 6.71824 18.5374 11.6629 12.7344 14.7801H50.0781V18.2199H12.7344C18.5374 21.3371 21.439 26.2818 21.439 33H18.215C18.215 28.4316 16.6031 24.7769 13.4329 22.1433C10.2627 19.5098 5.7492 18.2199 -0.00012207 18.2199V14.7801C5.7492 14.7801 10.2627 13.4365 13.4329 10.8029C16.6031 8.16938 18.215 4.56841 18.215 0Z"
      />
    </svg>
  )
}

export default IconArrowheadLeft
