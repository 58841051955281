import React from 'react'
import PropTypes from 'prop-types'
import { LinkBlockIcon, LinkBlockMain, LinkBlockText } from './index.style'
import SlideMask from '../SlideMask'

const LinkBlock = ({ icon, text, url }) => (
  <LinkBlockMain href={url}>
    {icon && <LinkBlockIcon>{icon}</LinkBlockIcon>}
    <LinkBlockText>
      <SlideMask>{text}</SlideMask>
    </LinkBlockText>
  </LinkBlockMain>
)

LinkBlock.propTypes = {
  icon: PropTypes.node,
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
}

export default LinkBlock
