export const font = {
  primary: 'GT America Condensed',
  secondary: 'TTTrailersVariable',
  tertiary: 'GT America',
  weight: {
    light: 300,
    regular: 400,
    medium: 500,
    bold: 700,
    extrabold: 800,
    secondary: {
      regular: `"wght" 52`,
      medium: `"wght" 64`,
      bold: `"wght" 110`,
      extrabold: `"wght" 134`,
    },
  },
}
