export const zIndex = {
  header: 20,
  footer: 20,
  mobileNav: 30,
  privacyPage: 39,
  mobileNavToggle: 40,
  cookieBar: 40,
  pageTransitionMask: 50,
  portal: 60,
}

// Padding hierarchy 6,12,18,24,30,48,60,72,120,180,240
