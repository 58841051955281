import React, { useState, useEffect } from 'react'
import { useLocation } from '@reach/router'
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'
import {
  CookieBarBtn,
  CookieBarFooter,
  CookieBarMain,
  CookieBarOverlay,
  CookieBarPageLink,
  CookieBarText,
} from './index.style'
import PageTransitionLink from '../PageTransitionLink'
import Routes from '../../routes'

const CookieBar = () => {
  const analyticsKey = 'gatsby-gdpr-google-analytics'
  const fbPixelKey = 'gatsby-gdpr-facebook-pixel'
  const location = useLocation()
  const [show, setShow] = useState(false)

  useEffect(() => {
    const cookie = document.cookie

    if (cookie.length === 0) {
      setShow(true)
    } else {
      let accepted = cookie
        .split('; ')
        .find(row => row.startsWith(`${analyticsKey}=`))

      if (accepted) {
        accepted = accepted.split('=')[1]
      }

      if (accepted === 'true') {
        setShow(false)
      } else {
        setShow(true)
      }
    }
  }, [])

  const enableCookies = () => {
    document.cookie = `${analyticsKey}=true;path=/`
    document.cookie = `${fbPixelKey}=true;path=/`
    initializeAndTrack(location)
    setShow(false)
  }

  return (
    <CookieBarOverlay show={show}>
      <CookieBarMain show={show}>
        <CookieBarText>
          We use our own and third-party cookies to personalize your experience
          and the promotions you see.
          <br />
          By visiting our website or transacting with us, you agree to this. To
          find out more, including which third-party cookies we place and how to
          manage cookies, see our{' '}
          <PageTransitionLink to={Routes.home}>
            privacy policy.
          </PageTransitionLink>
        </CookieBarText>

        <CookieBarFooter>
          <CookieBarBtn onClick={enableCookies}>I agree</CookieBarBtn>
          <CookieBarPageLink>
            <PageTransitionLink to={Routes.privacyPolicy}>
              View privacy policy
            </PageTransitionLink>
          </CookieBarPageLink>
        </CookieBarFooter>
      </CookieBarMain>
    </CookieBarOverlay>
  )
}

export default CookieBar
