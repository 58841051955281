import gsap from 'gsap'
import { ScrollToPlugin } from 'gsap/all'
import { isMobile } from 'react-device-detect'
gsap.registerPlugin(ScrollToPlugin)

export function disablePageScroll() {
  document.body.classList.add('disable-scroll')
}

export function enablePageScroll() {
  document.body.classList.remove('disable-scroll')
}

export function scrollTo(id) {
  const targetEl = document.getElementById(id)

  if (isMobile) {
    gsap.to(window, {
      duration: 1,
      ease: 'power2.inOut',
      scrollTo: targetEl,
      onComplete: () => {
        targetEl.focus()

        if (document.activeElement === targetEl) {
          return false
        } else {
          targetEl.setAttribute('tabindex', '-1')
          targetEl.focus()
        }
      },
    })
  } else {
    window.scroll.scrollTo(targetEl, {
      callback: () => {
        window.scroll.update()
      },
      disableLerp: true,
    })
  }
}

export function scrollToGSAP(id) {
  const targetEl = document.getElementById(id)

  gsap.to(window, {
    duration: 1,
    ease: 'power2.inOut',
    scrollTo: targetEl,
    onComplete: () => {
      targetEl.focus()

      if (document.activeElement === targetEl) {
        return false
      } else {
        targetEl.setAttribute('tabindex', '-1')
        targetEl.focus()
      }
    },
  })
}

export function focusElement(el) {
  el.setAttribute('tabindex', 0)
  el.focus()
}

export function random() {
  return (
    (Math.random() +
      Math.random() +
      Math.random() +
      Math.random() +
      Math.random() +
      Math.random() -
      3) /
    3
  )
}

export function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null
}

export function shuffle(array) {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex -= 1

    // And swap it with the current element.
    temporaryValue = array[currentIndex]
    array[currentIndex] = array[randomIndex]
    array[randomIndex] = temporaryValue
  }

  return array
}
