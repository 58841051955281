export const colors = {
  dark: '#202020',
  light: '#FCF5F3',
  light2: '#F4EDEB',
  light3: '#FEFDFD',
  orange: '#F27D58',
  red: '#BB4246',
  softRed: '#BD7B7B',
  blue: '#5BA5C5',
  yellowA: '#FFD64A',
  artists: '#9d9f9f',
  ourStory: '#eea497',
  events: '#88b2cc',
  contact: '#ffd294',
}
