import styled from 'styled-components'
import {
  clamp,
  horizontalScale,
  setVh,
} from '../../styles/utils/conversion.style'
import { zIndex } from '../../styles/vars/layout.style'
import { font } from '../../styles/vars/font.style'
import { mq } from '../../styles/vars/media-queries.style'
import { colors } from '../../styles/vars/colors.style'
import { breakpoints } from '../../styles/vars/breakpoints.style'
import { easings } from '../../styles/vars/easings.style'
import { PageTransitionLinkMain } from '../PageTransitionLink/index.style'
import { horizontalPadding } from '../../styles/utils/functions.style'

export const HeaderMain = styled.header`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 2.4rem;
  padding-bottom: 2.4rem;
  ${horizontalPadding()}
  z-index: ${zIndex.header};

  ${mq.belowHorizontal} {
    right: 0;
  }

  ${mq.horizontal} {
    position: ${({ fixed }) => (fixed ? 'fixed' : 'absolute')};
    right: ${({ show }) => (show ? 0 : undefined)};
    transform: ${({ ready }) =>
      ready ? 'translateY(0%)' : 'translateY(-100%)'};
    padding: 3rem 3.5rem;
    transition: transform 0.3s ${easings.out.cubic};
  }
`

export const HeaderLogo = styled.div`
  width: 5.8rem;
  height: 5.8rem;

  ${mq.horizontal} {
    display: ${({ show }) => (show ? undefined : 'none')};
    width: ${horizontalScale(58)};
    height: ${horizontalScale(58)};
  }

  ${PageTransitionLinkMain},
  svg {
    width: 100%;
    height: 100%;
  }
`

export const HeaderBack = styled.div`
  display: none;

  ${mq.horizontal} {
    display: ${({ show }) => (show ? 'block' : 'none')};
    width: ${horizontalScale(51)};
    height: ${horizontalScale(60)};
  }

  ${PageTransitionLinkMain},
  svg {
    width: 100%;
    height: 100%;
  }
`

export const MobileMenuToggle = styled.button`
  position: relative;
  width: 4.4rem;
  height: 4.4rem;
  margin-right: -0.8rem;
  z-index: ${zIndex.mobileNavToggle};
  background: none;
  border: none;

  ${mq.horizontal} {
    display: none;
  }
`

export const MobileMenuToggleBar = styled.span`
  position: absolute;
  right: 0.8rem;
  display: block;
  width: 2.8rem;
  height: 0.3rem;
  background-color: ${colors.dark};
  transition: transform 0.4s ${easings.text.in};
  transition-delay: ${({ show }) => (show ? '0.4s' : '0s')};

  :nth-child(1),
  :nth-child(2) {
    transform: ${({ show }) => (show ? 'scale(1)' : 'scale(0)')};
  }

  :nth-child(1) {
    top: 1.55rem;
    transform-origin: 0% 50%;
  }

  :nth-child(2) {
    top: 2.55rem;
    transform-origin: 100% 50%;
  }

  :nth-child(3),
  :nth-child(4) {
    top: 2rem;
  }

  :nth-child(3) {
    transform: ${({ show }) =>
      show ? 'rotate(45deg) scaleX(1)' : 'rotate(45deg) scaleX(0)'};
  }

  :nth-child(4) {
    transform: ${({ show }) =>
      show ? 'rotate(-45deg) scaleX(1)' : 'rotate(-45deg) scaleX(0)'};
  }
`

export const MobileNav = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  transform: ${({ show }) => (show ? 'translateY(0%)' : 'translateY(-100%)')};
  width: 100%;
  ${setVh('height', 100)}
  z-index: ${zIndex.mobileNav};
  overflow: scroll;
  background-color: ${colors.ourStory};
  transition: transform 0.5s ${easings.inOut.cubic};

  ${mq.horizontal} {
    display: none;
  }
`

export const MobileNavMain = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  ${clamp('padding', 24, 48, breakpoints.mobile, breakpoints.horizontal)}
`

export const MobileNavTitle = styled.h2`
  margin-top: 1.5rem;
  font-family: ${font.secondary};
  ${clamp('font-size', 26, 30, breakpoints.mobile, breakpoints.horizontal)}
  font-variation-settings: ${font.weight.secondary.bold};
  line-height: 0.84;
  text-transform: uppercase;
`

export const MobileMenu = styled.ul`
  padding: 0;
  ${clamp('padding-bottom', 24, 48, breakpoints.mobile, breakpoints.horizontal)}
  list-style: none;
`

export const MobileMenuItem = styled.li`
  position: relative;

  & + & {
    margin-top: 0.6rem;
  }

  a {
    position: relative;
    display: inline-flex;
    font-family: ${font.secondary};
    ${clamp('font-size', 86, 180, breakpoints.mobile, breakpoints.horizontal)}
    font-variation-settings: ${font.weight.secondary.bold};
    line-height: 0.84;
    text-transform: uppercase;
  }

  .split__text {
    :before {
      content: '';
      position: absolute;
      bottom: 0.2rem;
      right: 0;
      left: 0;
      transform-origin: 0% 50%;
      transform: scaleX(${({ active }) => (active ? 1 : 0)});
      height: 0.2rem;
      background-color: ${colors.dark};
      transition: transform 0.3s ${easings.out.cubic};
    }
  }
`

export const TicketLink = styled.a`
  border: 0.1rem solid ${colors.dark};
  padding: 1.4rem 1.4rem;
  position: relative;
  display: inline-flex;
  font-family: ${font.secondary};
  ${clamp('font-size', 32, 64, breakpoints.mobile, breakpoints.horizontal)}
  font-variation-settings: ${font.weight.secondary.bold};
  ${clamp('margin-bottom', 24, 48, breakpoints.mobile, breakpoints.horizontal)}
  position: relative;
  line-height: 0.84;
  text-transform: uppercase;
`

export const MobileMenuItemSecondary = styled.li`
  ${MobileMenuItem} + & {
    ${clamp('margin-top', 21, 48, breakpoints.mobile, breakpoints.horizontal)}
  }

  a {
    display: block;
    ${clamp('font-size', 16, 24, breakpoints.mobile, breakpoints.horizontal)}
    font-weight: ${font.weight.light};
    line-height: 1.6;
    letter-spacing: -0.02em;
  }
`

export const MobileNavFooter = styled.div`
  margin-top: auto;
  ${clamp('margin-right', -48, -24, breakpoints.mobile, breakpoints.horizontal)}
`

export const MobileNavCopyright = styled.p`
  ${clamp('font-size', 12, 16, breakpoints.mobile, breakpoints.horizontal)}
  font-weight: ${font.weight.light};
  line-height: 1.2;
  letter-spacing: -0.02em;

  a {
    display: inline-flex;
    margin-left: 1em;
    border-bottom: none;
  }
`

export const Menu = styled.ul`
  display: none;

  ${mq.horizontal} {
    display: ${({ show }) => (show ? 'flex' : 'none')};
    flex-direction: column;
    flex-direction: row;
    align-items: center;
    opacity: ${({ fade }) => (fade ? 0 : 1)};
    padding: 0;
    list-style: none;
    pointer-events: ${({ fade }) => (fade ? 'none' : '')};
    transition: opacity 0.3s ${easings.out.cubic};
  }
`

export const MenuItem = styled.li`
  position: relative;

  & + & {
    ${mq.horizontal} {
      margin-left: 3.2rem;
    }
  }

  &:before {
    display: ${({ border }) => (border ? 'none' : 'block')};
  }

  @media screen and (prefers-reduced-motion: no-preference) {
    :before {
      content: '';
      position: absolute;
      bottom: ${horizontalScale(1)};
      right: 0;
      left: 0;
      transform-origin: 0% 50%;
      transform: scaleX(${({ active }) => (active ? 1 : 0)});
      height: 0.2rem;
      background-color: ${({ light }) => (light ? colors.light : colors.dark)};
      transition: all 0.3s ${easings.out.cubic};
    }
  }

  a {
    font-family: ${font.secondary};
    font-variation-settings: ${font.weight.secondary.bold};
    font-size: 2.6rem;
    line-height: 1;
    text-transform: uppercase;

    ${mq.horizontal} {
      color: ${({ light }) => (light ? colors.light : colors.dark)};
      transition: color 0.2s ease;
    }
  }
`

export const TicketMenuItem = styled.a`
  display: block;
  padding: 0.6rem 1.4rem 0.4rem;
  border: solid 0.1rem currentColor;
`
