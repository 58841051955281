import styled from 'styled-components'
import { setVh } from '../../styles/utils/conversion.style'

export const GridlinesMain = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: ${props => (props.show ? 'block' : 'none')};
  pointer-events: none;
  z-index: 1000;

  > [data-grid] {
    height: 100%;
  }
`

export const GridlinesColumn = styled.div`
  width: 100%;
  ${setVh('height', 100)}
  border-left: 1px solid rgba(0, 0, 0, 0.15);
  border-right: 1px solid rgba(0, 0, 0, 0.15);
`
