import React from 'react'
import PropTypes from 'prop-types'
import {
  SlideMaskChildVertical,
  SlideMaskChildHorizontal,
  SlideMaskMain,
  SlideMaskChildHorizontalReverse,
} from './index.style'

const SlideMask = ({ direction = 'vertical', children }) => (
  <SlideMaskMain>
    {direction === 'vertical' && (
      <>
        <SlideMaskChildVertical>{children}</SlideMaskChildVertical>
        <SlideMaskChildVertical aria-hidden>{children}</SlideMaskChildVertical>
      </>
    )}

    {direction === 'horizontal' && (
      <>
        <SlideMaskChildHorizontal>{children}</SlideMaskChildHorizontal>
        <SlideMaskChildHorizontal aria-hidden>
          {children}
        </SlideMaskChildHorizontal>
      </>
    )}

    {direction === 'horizontal-reverse' && (
      <>
        <SlideMaskChildHorizontalReverse>
          {children}
        </SlideMaskChildHorizontalReverse>
        <SlideMaskChildHorizontalReverse aria-hidden>
          {children}
        </SlideMaskChildHorizontalReverse>
      </>
    )}
  </SlideMaskMain>
)

SlideMask.propTypes = {
  direction: PropTypes.oneOf(['vertical', 'horizontal', 'horizontal-reverse']),
  children: PropTypes.node,
}

export default SlideMask
