import styled from 'styled-components'
import { colors } from '../../styles/vars/colors.style'
import { font } from '../../styles/vars/font.style'
import { zIndex } from '../../styles/vars/layout.style'
import { mq } from '../../styles/vars/media-queries.style'
import { horizontalPadding } from '../../styles/utils/functions.style'
import { PageTransitionLinkMain } from '../PageTransitionLink/index.style'

export const FooterMain = styled.footer`
  position: relative;
  padding-top: 6.9rem;
  padding-bottom: 3.8rem;
  ${horizontalPadding()}
  overflow: hidden;
  z-index: ${zIndex.footer};
  color: ${colors.light};
  background-color: ${colors.dark};

  ${mq.horizontal} {
    display: none;
  }
`

export const FooterText = styled.p`
  font-family: ${font.secondary};
  font-variation-settings: ${font.weight.secondary.medium};
  font-size: 2.8rem;
  line-height: 1.2;
  text-transform: uppercase;
`

export const FooterSocial = styled.div`
  margin-top: 2.4rem;
  margin-right: -3rem;
`

export const FooterCopyright = styled.p`
  margin-top: 0.4rem;
  font-size: 1.4rem;
  line-height: 1.2;
  letter-spacing: -0.02em;

  ${PageTransitionLinkMain},
  a {
    margin-left: 1em;
  }

  a {
    display: inline-flex;
    border-bottom: none;
  }
`
