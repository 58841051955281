import React from 'react'
import PropTypes from 'prop-types'
import { usePageTransition } from '../../hooks/usePageTransition'
import { useContext } from 'react'
import { ContextDispatch, ContextState } from '../../context/Context'
import { colors } from '../../styles/vars/colors.style'
import Routes from '../../routes'
import { PageTransitionLinkMain } from './index.style'

const PageTransitionLink = ({ children, to, fill }) => {
  const pageTransition = usePageTransition()
  const store = useContext(ContextState)
  const storeDispatch = useContext(ContextDispatch)

  const getMaskColor = pathname => {
    if (pathname === Routes.ourStory) {
      return colors.ourStory
    }

    if (pathname === Routes.artists) {
      return colors.artists
    }

    if (pathname === Routes.events) {
      return colors.events
    }

    if (pathname === Routes.contact) {
      return colors.contact
    }

    return colors.light
  }

  return (
    <PageTransitionLinkMain
      to={to}
      exit={pageTransition.exit}
      entry={pageTransition.entry}
      fill={fill ? 'true' : undefined}
      onClick={() => {
        if (
          to.indexOf(store.pathname) === -1 ||
          to.length !== store.pathname.length
        ) {
          const maskColor = getMaskColor(to)
          storeDispatch({ type: 'SET_MASK_COLOR', payload: maskColor })
          storeDispatch({ type: 'HIDE_MOBILE_NAV' })
          storeDispatch({ type: 'UPDATE_PATHNAME', payload: to })
          storeDispatch({ type: 'SHOW_TRANSITION_MASK' })
        }
      }}
    >
      {children}
    </PageTransitionLinkMain>
  )
}

PageTransitionLink.propTypes = {
  children: PropTypes.node,
  fill: PropTypes.bool,
  to: PropTypes.string,
}

PageTransitionLink.defaultProps = {
  fill: false,
}

export default PageTransitionLink
