const Routes = {
  home: '/',
  ourStory: '/our-story',
  artists: '/artists',
  partners: '/partners',
  events: '/events',
  press: '/press',
  contact: '/contact',
  privacyPolicy: '/privacy-policy',
  instagram: 'https://www.instagram.com/thelaartbox',
  facebook: 'https://www.facebook.com/TheLAArtBox/',
  linkedin: 'https://www.linkedin.com/company/the-la-art-box/',
  newsletterUrl: 'https://laabfriends.splashthat.com/',
  collaborateEmail: 'collaboration@thelaartbox.com',
  infoEmail: 'info@thelaartbox.com',
  tickets: 'https://laab.ticketspice.com/thelaartbox-presents-luminarium',
}

export default Routes

export const hiddenRoutes = [
  Routes.events,
  Routes.artists,
  Routes.press,
  Routes.partners,
]

export const isExhibitPage = pathname => {
  if (pathname === Routes.press) return

  return hiddenRoutes.reduce((state, route) => {
    return pathname.includes(route) && pathname.length > route.length + 1
      ? false
      : state
  }, true)
}
