import React from 'react'

const IconLinkedIn = ({
  width = 13,
  height = 13,
  fill = 'currentColor',
  responsive = false,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 13 13"
      aria-hidden="true"
      focusable="false"
      {...svgAttributes}
    >
      <path
        fill={fill}
        d="M2.697 1.312c0 .725-.6 1.312-1.343 1.312-.742 0-1.343-.587-1.343-1.312C.01.588.612 0 1.354 0s1.343.588 1.343 1.312zm.011 2.362H0v8.397h2.708V3.674zm4.324 0H4.34v8.397h2.691V7.663c0-2.45 3.266-2.651 3.266 0v4.408H13V6.754c0-4.136-4.833-3.985-5.968-1.949V3.674z"
      />
    </svg>
  )
}

export default IconLinkedIn
