import { breakpoints } from '../vars/breakpoints.style'
import { mq } from '../vars/media-queries.style'

export function pxToRem(pixelValue) {
  return `${pixelValue / 10}rem`
}

export function pxToEm(pixelValue, pixelBase) {
  return `${pixelValue / pixelBase}em`
}

export function getClampValue(
  minValue,
  maxValue,
  minBreakpoint = breakpoints.mobile,
  maxBreakpoint = breakpoints.deskL
) {
  const valuesAreNegative =
    Math.sign(minValue) + Math.sign(maxValue) === -2 ? true : false
  const minValueRem = minValue / 10
  const maxValueRem = maxValue / 10
  const minBreakpointRem = minBreakpoint / 10
  const maxBreakpointRem = maxBreakpoint / 10
  const slope =
    (maxValueRem - minValueRem) / (maxBreakpointRem - minBreakpointRem)
  const yAxisIntersection = -minBreakpointRem * slope + minValueRem
  const preferredValue = `${yAxisIntersection.toFixed(4)}rem + ${(
    slope * 100
  ).toFixed(4)}vw`

  return `clamp(${
    valuesAreNegative ? maxValueRem : minValueRem
  }rem, ${preferredValue}, ${valuesAreNegative ? minValueRem : maxValueRem}rem)`
}

export function clamp(
  property,
  minValue,
  maxValue,
  minBreakpoint = breakpoints.mobile,
  maxBreakpoint = breakpoints.deskL
) {
  return `
    ${property}: ${pxToRem(minValue)};
    ${property}: ${getClampValue(
    minValue,
    maxValue,
    minBreakpoint,
    maxBreakpoint
  )};

    ${mq.deskL} {
      ${property}: ${(maxValue / breakpoints.deskL) * 100}vw;
    }
  `
}

export function horizontalScale(value) {
  return `calc(var(--vh, 1vh) * ${(value / 1080) * 100})`
}

export function setVh(property, value) {
  return `
    ${property}: ${value}vh;
    ${property}: calc(var(--vh, 1vh) * ${value});
  `
}
