import React from 'react'

const IconInstagram = ({
  width = 14,
  height = 15,
  fill = 'currentColor',
  responsive = false,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 15"
      aria-hidden="true"
      focusable="false"
      {...svgAttributes}
    >
      <path
        fill={fill}
        d="M4.064 0C1.826 0 0 1.956 0 4.354v6.292C0 13.044 1.826 15 4.064 15h5.872C12.174 15 14 13.044 14 10.646V4.354C14 1.956 12.174 0 9.936 0H4.064zm0 1.154h5.872c1.656 0 2.987 1.424 2.987 3.2v6.292c0 1.774-1.33 3.2-2.987 3.2H4.064c-1.656 0-2.987-1.424-2.987-3.2V4.354c0-1.774 1.33-3.2 2.987-3.2zm6.975 1.154c-.447 0-.808.387-.808.865s.361.865.808.865c.445 0 .807-.387.807-.865s-.362-.865-.807-.865zM7 3.462c-2.076 0-3.77 1.814-3.77 4.038S4.925 11.539 7 11.539s3.77-1.815 3.77-4.039c0-2.224-1.694-4.038-3.77-4.038zm0 1.153c1.493 0 2.692 1.285 2.692 2.885 0 1.6-1.199 2.885-2.692 2.885S4.308 9.1 4.308 7.5c0-1.6 1.199-2.885 2.692-2.885z"
      />
    </svg>
  )
}

export default IconInstagram
