import React, { useReducer, createContext } from 'react'
import { colors } from '../styles/vars/colors.style'

export const ContextState = createContext()
export const ContextDispatch = createContext()

const initialState = {
  showTransitionMask: true,
  mobileNavIsOpen: false,
  contactIsOpen: true,
  menuIsLight: false,
  menuIsHidden: false,
  pathname: typeof window === 'undefined' ? null : window.location.pathname,
  popstate: 0,
  maskColor: colors.ourStory,
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_PATHNAME': {
      return {
        ...state,
        pathname: action.payload,
      }
    }

    case 'SET_LIGHT_MENU': {
      return {
        ...state,
        menuIsLight: true,
      }
    }

    case 'SET_DARK_MENU': {
      return {
        ...state,
        menuIsLight: false,
      }
    }

    case 'SET_VISIBLE_MENU': {
      return {
        ...state,
        menuIsHidden: false,
      }
    }

    case 'SET_HIDDEN_MENU': {
      return {
        ...state,
        menuIsHidden: true,
      }
    }

    case 'SET_MASK_COLOR': {
      return {
        ...state,
        maskColor: action.payload,
      }
    }

    case 'UPDATE_POP_STATE': {
      return {
        ...state,
        popstate: state.popstate + 1,
      }
    }

    case 'SHOW_TRANSITION_MASK': {
      return {
        ...state,
        showTransitionMask: true,
      }
    }
    case 'HIDE_TRANSITION_MASK': {
      return {
        ...state,
        showTransitionMask: false,
      }
    }

    case 'SHOW_MOBILE_NAV': {
      return {
        ...state,
        mobileNavIsOpen: true,
      }
    }
    case 'HIDE_MOBILE_NAV': {
      return {
        ...state,
        mobileNavIsOpen: false,
      }
    }
    case 'TOGGLE_MOBILE_NAV': {
      return {
        ...state,
        mobileNavIsOpen: !state.mobileNavIsOpen,
      }
    }

    default:
      throw new Error('Bad action type')
  }
}

const Context = props => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <ContextState.Provider value={state}>
      <ContextDispatch.Provider value={dispatch}>
        {props.children}
      </ContextDispatch.Provider>
    </ContextState.Provider>
  )
}

export default Context
