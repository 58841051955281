import { useContext } from 'react'
import { ContextDispatch } from '../../context/Context'
import { animation } from '../../styles/vars/animation.style'

export const usePageTransition = () => {
  const storeDispatch = useContext(ContextDispatch)

  return {
    exit: {
      delay: animation.pageExitDuration,
      length: 0,
      zIndex: 2,
    },
    entry: {
      trigger: () => storeDispatch({ type: 'HIDE_TRANSITION_MASK' }),
      delay: 0,
      length: animation.pageEntryDuration,
      zIndex: 1,
    },
  }
}
