import styled from 'styled-components'
import { easings } from '../../../styles/vars/easings.style'

const BaseSplitTextWrapper = styled.span`
  display: block;

  .split__mask {
    overflow: hidden;
  }

  .split__text {
    will-change: transform;
    transform: translateY(105%);
    transition: transform 1s ${easings.text.in};

    [data-inview='false'] & {
      transition-delay: 0s;
    }

    a {
      display: inline-flex;
      line-height: 1.28;
    }
  }

  [data-inview='true'] &.split .split__mask .split__text {
    transform: translate(0, 0);
  }
`

export const SplitTextWrapper = styled(BaseSplitTextWrapper)`
  .split__mask {
    ${({ delay, heading }) => {
      let styles = ``
      let itemDelay = 0.05

      for (let i = 0; i < 30; i++) {
        if (heading) {
          const lineDelay = i === 0 ? 0 : 0.4
          styles += `
            :nth-child(${i + 1}) .split__text {
              transition-delay: ${delay + lineDelay + itemDelay * (i + 1)}s;
            }
          `
        } else {
          styles += `
            :nth-child(${i + 1}) .split__text {
              transition-delay: ${delay + itemDelay * (i + 1)}s;
            }
          `
        }
      }

      return styles
    }}
  }

  ${({ heading }) => {
    if (heading) {
      return `
        .split__mask:first-child .split__text {
          transform: translateX(-102%);
          transition-duration: 1.3s;
        }
      `
    }
  }}
`
