import { breakpoints } from './breakpoints.style'

export const mq = {
  tiny: `@media (min-width: ${breakpoints.tiny}px)`,
  mobile: `@media (min-width: ${breakpoints.mobile}px)`,
  mobileL: `@media (min-width: ${breakpoints.mobileL}px)`,
  belowTabletP: `@media (max-width: ${breakpoints.tabletP - 1}px)`,
  tabletP: `@media (min-width: ${breakpoints.tabletP}px)`,
  tabletPToDesk: `@media (min-width: ${
    breakpoints.tabletP
  }px) and (max-width: ${breakpoints.desk - 1}px)`,
  belowTabletL: `@media (max-width: ${breakpoints.tabletL - 1}px)`,
  belowHorizontal: `@media (max-width: ${breakpoints.horizontal - 1}px)`,
  horizontal: `@media (min-width: ${breakpoints.horizontal}px)`,
  tabletL: `@media (min-width: ${breakpoints.tabletL}px)`,
  desk: `@media (min-width: ${breakpoints.desk}px)`,
  deskL: `@media (min-width: ${breakpoints.deskL}px)`,
  contained: `@media (min-width: ${breakpoints.contained}px)`,
}
