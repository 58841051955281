import { useEffect, useContext } from 'react'
// We are excluding this from loading at build time in gatsby-node.js
import LocomotiveScroll from 'locomotive-scroll'
import { animation } from '../../styles/vars/animation.style'
import { ContextDispatch } from '../../context/Context'
import { isDesktop } from 'react-device-detect'
import Routes from '../../routes'

const Scroll = callbacks => {
  const dispatch = useContext(ContextDispatch)

  useEffect(() => {
    const scrollEl = document.getElementById('scroll-container')

    if (
      !scrollEl ||
      callbacks.callbacks.pathname.includes(Routes.privacyPolicy)
    )
      return

    const locomotiveScroll = new LocomotiveScroll({
      el: scrollEl,
      smooth: true,
      smoothMobile: false,
      drag: true,
      direction: 'horizontal',
      getDirection: true,
      touchMultiplier: 2.5,
      firefoxMultiplier: 70,
      lerp: 0.1,
      repeat: false,
      class: 'is-inview',
      reloadOnContextChange: true,
    })

    const fixedImage = document.getElementById('aboutIntroBackground')

    window.scroll = locomotiveScroll
    window.scroll.stop()

    const timeout = setTimeout(() => {
      window.scroll.update()
      window.scroll.start()

      if (isDesktop) {
        window.scroll.on('call', (value, way) => {
          if (value === 'latest') {
            if (way === 'enter') {
              dispatch({ type: 'SET_LIGHT_MENU' })
            }

            if (way === 'exit') {
              dispatch({ type: 'SET_DARK_MENU' })
            }
          }
        })
      }

      window.scroll.on('scroll', args => {
        document.documentElement.setAttribute('data-direction', args.direction)

        if (fixedImage && args.scroll.x < window.innerWidth) {
          fixedImage.style.transform = `translateX(-${args.scroll.x * 0.4}px)`
        }
      })
    }, (animation.pageExitDuration + animation.pageEntryDuration) * 1000)

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }

      if (window.scroll) {
        window.scroll.destroy()
      }
    }
  }, [callbacks.callbacks.pathname, dispatch])

  return null
}

export default Scroll
