import React from 'react'
import { colors } from '../../../styles/vars/colors.style'

const Logo = ({
  width = 58,
  height = 58,
  fill = colors.dark,
  responsive = true,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 58 58"
      aria-labelledby="logoTitle"
      focusable="false"
      role="img"
      {...svgAttributes}
    >
      <title id="logoTitle">LA Art Box</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.048 10.148v37.656l6.134 6.52V3.628l-6.134 6.52zm-.818-.325V48.13L58 56.387V1.565l-7.77 8.258zM6.952 10.148v37.656l-6.134 6.52V3.628l6.134 6.52zm.818-.325V48.13L0 56.387V1.565l7.77 8.258zM10.164 6.958h37.624l6.515-6.14H3.649l6.515 6.14zm-.325.819h38.274L56.364 0H1.588l8.251 7.777zM10.164 51.042h37.624l6.515 6.14H3.649l6.515-6.14zm-.325-.819h38.274L56.364 58H1.588l8.251-7.777z"
        fill={fill}
      />
      <path
        d="M21.88 26.857v1.745h-7.077V18.518h1.86v8.339h5.218zM33.037 28.603h-2.796v-2.266H25.34v2.265h-2.797l4.036-10.084h2.436l4.022 10.085zm-3.46-3.953l-1.787-4.458-1.773 4.458h3.56z"
        fill={fill}
      />
      <path
        d="M22.543 28.602h2.797v2.266h4.9v-2.265h2.797l-4.036 10.084h-2.436l-4.022-10.085zm3.46 3.954l1.787 4.457 1.773-4.457h-3.56zM42.667 35.802c0 1.587-1.283 2.885-2.869 2.885h-6.097V28.603h5.824a2.739 2.739 0 012.738 2.74c0 1.054-.778 1.775-1.196 2.035.548.26 1.6 1.053 1.6 2.424zM39.15 30.29h-3.647v2.38h3.647c.663 0 1.196-.52 1.196-1.183s-.533-1.197-1.196-1.197zm1.657 5.352c0-.75-.605-1.342-1.354-1.342h-3.95V37h3.95c.75 0 1.354-.606 1.354-1.356z"
        fill={fill}
      />
    </svg>
  )
}

export default Logo
