import React, { useState, useEffect, useContext, useRef } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Logo from '../_svgs/Logo'
import Routes, { hiddenRoutes, isExhibitPage } from '../../routes'
import PageTransitionLink from '../PageTransitionLink'
import { ContextDispatch, ContextState } from '../../context/Context'
import IconInstagram from '../_svgs/IconInstagram'
import IconFacebook from '../_svgs/IconFacebook'
import IconArrowheadLeft from '../_svgs/IconArrowheadLeft'
import AnimateSplitText from '../_animation/AnimateSplitText'
import AnimateFadeIn from '../_animation/AnimateFadeIn'
import { animation } from '../../styles/vars/animation.style'
import Spacer from '../Spacer'
import SlideMask from '../SlideMask'
import LinkBlock from '../LinkBlock'
import {
  HeaderLogo,
  HeaderMain,
  Menu,
  MenuItem,
  MobileMenu,
  MobileMenuToggle,
  MobileMenuItem,
  // MobileMenuItemSecondary,
  MobileNav,
  MobileNavCopyright,
  MobileNavFooter,
  MobileNavMain,
  MobileNavTitle,
  MobileMenuToggleBar,
  TicketLink,
  TicketMenuItem,
  HeaderBack,
} from './index.style'
import { disablePageScroll, enablePageScroll } from '../../utils/utils'
import { isMobile } from 'react-device-detect'

const navItems = [
  {
    to: Routes.ourStory,
    label: 'Our Story',
  },
  {
    to: Routes.artists,
    label: 'Artists',
  },
  {
    to: Routes.events,
    label: 'Events',
  },
  {
    to: Routes.press,
    label: 'Press',
  },
  {
    to: Routes.contact,
    label: 'Contact',
  },
]

// const secondaryNavItems = [
//   { to: Routes.privacyPolicy, label: 'Privacy Policy' },
//   { to: Routes.privacyPolicy, label: 'Terms & conditions' },
//   { to: Routes.privacyPolicy, label: 'Cookie Policy' },
//   { to: Routes.privacyPolicy, label: 'Accessibility' },
// ]

const Header = ({ location }) => {
  const store = useContext(ContextState)
  const dispatch = useContext(ContextDispatch)
  const [show, setShow] = useState(isExhibitPage(location.pathname))
  const [ready, setReady] = useState(false)
  const [showMobileNav, setShowMobileNav] = useState(false)
  const [backRoute, setBackRoute] = useState(Routes.home)
  const mobileNavRef = useRef()
  const openDelay = 0.4
  const animationDelay = 0.05
  const prefersReducedMotion =
    typeof window === 'undefined'
      ? null
      : window.matchMedia('(prefers-reduced-motion: reduce)')
  let navItemIndex = 0
  const { contentfulGlobals } = useStaticQuery(graphql`
    query HeaderQuery {
      contentfulGlobals {
        menuCtaText
        menuCtaLink
      }
    }
  `)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setReady(true)
    }, (animation.pageEntryDuration + animation.siteLoadDelay) * 1000)

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [])

  useEffect(() => {
    if (store.mobileNavIsOpen) {
      disablePageScroll()
      mobileNavRef.current.scrollTop = 0
      setShowMobileNav(true)
    } else {
      enablePageScroll()
      setShowMobileNav(false)
    }
  }, [store.mobileNavIsOpen])

  useEffect(() => {
    const timeout = setTimeout(() => {
      const path = store.pathname
      const show =
        !prefersReducedMotion || prefersReducedMotion.matches
          ? true
          : isExhibitPage(path)

      setBackRoute(
        path === Routes.press
          ? Routes.home
          : hiddenRoutes.reduce((targetRoute, route) => {
              if (path.includes(route)) {
                targetRoute =
                  route === Routes.partners ? Routes.ourStory : route
              }
              return targetRoute
            }, Routes.home)
      )

      setShow(show)
    }, animation.maskExitDuration * 1000)

    return () => {
      clearTimeout(timeout)
    }
  }, [store.pathname, prefersReducedMotion])

  const isMenuItemActive = target => {
    return !store.pathname
      ? location.pathname.indexOf(target) > -1
      : store.pathname.indexOf(target) > -1
  }

  return (
    <HeaderMain show={show} ready={ready} fixed={isMobile}>
      <HeaderLogo show={show}>
        <PageTransitionLink to={Routes.home}>
          <Logo />
        </PageTransitionLink>
      </HeaderLogo>

      <HeaderBack show={!show}>
        <PageTransitionLink to={backRoute}>
          <SlideMask direction="horizontal-reverse">
            <IconArrowheadLeft responsive />
          </SlideMask>
        </PageTransitionLink>
      </HeaderBack>

      <MobileMenuToggle
        type="button"
        onClick={() => dispatch({ type: 'TOGGLE_MOBILE_NAV' })}
        aria-label="Toggle menu"
      >
        <MobileMenuToggleBar show={!store.mobileNavIsOpen} />
        <MobileMenuToggleBar show={!store.mobileNavIsOpen} />
        <MobileMenuToggleBar show={store.mobileNavIsOpen} />
        <MobileMenuToggleBar show={store.mobileNavIsOpen} />
      </MobileMenuToggle>

      <MobileNav
        ref={mobileNavRef}
        show={showMobileNav}
        data-inview={store.mobileNavIsOpen}
      >
        <MobileNavMain>
          <MobileNavTitle>
            <AnimateSplitText delay={openDelay}>Menu</AnimateSplitText>
          </MobileNavTitle>

          <Spacer axis="vertical" size={[32, 48]} />

          <MobileMenu>
            {React.Children.toArray(
              navItems.map((navItem, i) => {
                navItemIndex++
                return (
                  <MobileMenuItem active={isMenuItemActive(navItem.to)}>
                    <PageTransitionLink to={navItem.to}>
                      <AnimateSplitText
                        heading
                        delay={openDelay + navItemIndex * animationDelay}
                      >
                        {navItem.label}
                      </AnimateSplitText>
                    </PageTransitionLink>
                  </MobileMenuItem>
                )
              })
            )}

            {/* {React.Children.toArray(
              secondaryNavItems.map((navItem, i) => {
                navItemIndex++
                return (
                  <MobileMenuItemSecondary
                    active={
                      !store.pathname
                        ? location.pathname.indexOf(navItem.to) > -1
                        : store.pathname.indexOf(navItem.to) > -1
                    }
                  >
                    <PageTransitionLink to={navItem.to}>
                      <AnimateSplitText
                        delay={openDelay + navItemIndex * animationDelay}
                      >
                        {navItem.label}
                      </AnimateSplitText>
                    </PageTransitionLink>
                  </MobileMenuItemSecondary>
                )
              })
            )} */}
          </MobileMenu>

          <AnimateFadeIn
            delay={openDelay + (navItemIndex + 1) * animationDelay}
          >
            <TicketLink href={contentfulGlobals.menuCtaLink} target="_blank">
              {contentfulGlobals.menuCtaText}
            </TicketLink>
          </AnimateFadeIn>

          <MobileNavFooter>
            <AnimateFadeIn
              delay={openDelay + (navItemIndex + 1) * animationDelay}
            >
              <LinkBlock
                url={Routes.instagram}
                icon={<IconInstagram />}
                text="@thelaartbox"
              />
            </AnimateFadeIn>

            <AnimateFadeIn
              delay={openDelay + (navItemIndex + 2) * animationDelay}
            >
              <LinkBlock
                url={Routes.facebook}
                icon={<IconFacebook />}
                text="Thelaartbox"
              />
            </AnimateFadeIn>

            <AnimateFadeIn
              delay={openDelay + (navItemIndex + 3) * animationDelay}
            >
              <LinkBlock url={Routes.newsletterUrl} text="Join our guestlist" />
            </AnimateFadeIn>
          </MobileNavFooter>

          <Spacer axis="vertical" size={[24, 48]} />

          <MobileNavCopyright>
            <AnimateSplitText
              delay={openDelay + (navItemIndex + 3) * animationDelay}
            >
              ©{new Date().getFullYear()}, THE LA ART BOX. All rights reserved{' '}
              <PageTransitionLink to={Routes.privacyPolicy}>
                Privacy policy
              </PageTransitionLink>
            </AnimateSplitText>
          </MobileNavCopyright>
        </MobileNavMain>
      </MobileNav>

      <Menu show={show} fade={store.menuIsHidden}>
        {React.Children.toArray(
          navItems.map(navItem => (
            <MenuItem
              light={store.menuIsLight}
              active={isMenuItemActive(navItem.to)}
            >
              <PageTransitionLink to={navItem.to}>
                {isMenuItemActive(navItem.to) ? (
                  navItem.label
                ) : (
                  <SlideMask>{navItem.label}</SlideMask>
                )}
              </PageTransitionLink>
            </MenuItem>
          ))
        )}
        <MenuItem light={store.menuIsLight} active={false} border={true}>
          <TicketMenuItem href={contentfulGlobals.menuCtaLink} target="_blank">
            <SlideMask>{contentfulGlobals.menuCtaText}</SlideMask>
          </TicketMenuItem>
        </MenuItem>
      </Menu>
    </HeaderMain>
  )
}

export default Header
