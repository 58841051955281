import React, { useRef, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import gsap from 'gsap'
import { ContextState } from '../../context/Context'
import { TransitionMaskWrapper, TransitionMaskInner } from './index.style'
import { animation } from '../../styles/vars/animation.style'

const TransitionMask = ({ color }) => {
  const store = useContext(ContextState)
  const maskRef = useRef()
  const maskInnerRef = useRef()

  useEffect(() => {
    const showMask = () => {
      maskRef.current.style.display = 'block'

      gsap.to(maskInnerRef.current, {
        xPercent: 0,
        duration: animation.maskExitDuration,
        ease: 'power3.inOut',
      })
    }

    const hideMask = () => {
      gsap.to(maskInnerRef.current, {
        yPercent: -100,
        delay: 0.1,
        duration: animation.pageEntryDuration,
        ease: 'power3.inOut',
        onComplete: () => {
          gsap.set(maskInnerRef.current, {
            xPercent: -100,
            yPercent: 0,
          })
          maskRef.current.style.display = 'none'
        },
      })
    }

    if (store.showTransitionMask) {
      showMask()
    } else {
      hideMask()
    }
  }, [store.showTransitionMask])

  return (
    <TransitionMaskWrapper ref={maskRef}>
      <TransitionMaskInner ref={maskInnerRef} color={color} />
    </TransitionMaskWrapper>
  )
}

TransitionMask.propTypes = {
  color: PropTypes.string,
}

export default TransitionMask
