import { breakpoints } from '../vars/breakpoints.style'
import { clamp } from './conversion.style'

export function flow(scale = 1, direction = 'top') {
  return `
    > * + * {
      margin-${direction}: ${scale}em;
    }
  `
}

export function horizontalPadding(direction = 'both') {
  let styles = ''

  if (direction === 'left' || direction === 'both') {
    styles += `
      ${clamp(
        'padding-left',
        20,
        32,
        breakpoints.mobile,
        breakpoints.horizontal
      )}
    `
  }

  if (direction === 'right' || direction === 'both') {
    styles += `
      ${clamp(
        'padding-right',
        20,
        32,
        breakpoints.mobile,
        breakpoints.horizontal
      )}
    `
  }

  return styles
}
