import React from 'react'
import Routes from '../../routes'
import IconInstagram from '../_svgs/IconInstagram'
import IconFacebook from '../_svgs/IconFacebook'
import IconLinkedIn from '../_svgs/IconLinkedIn'
import {
  FooterCopyright,
  FooterMain,
  FooterSocial,
  FooterText,
} from './index.style'
import LinkBlock from '../LinkBlock'
import PageTransitionLink from '../PageTransitionLink'

const Footer = () => (
  <FooterMain>
    <FooterText>Stay in touch with all the latest from LA ART BOX</FooterText>

    <FooterSocial>
      <LinkBlock
        url={Routes.instagram}
        icon={<IconInstagram />}
        text="@thelaartbox"
      />
      <LinkBlock
        url={Routes.facebook}
        icon={<IconFacebook />}
        text="TheLAArtBox"
      />
      <LinkBlock
        url={Routes.linkedin}
        icon={<IconLinkedIn />}
        text="The LA ART BOX"
      />
      <LinkBlock url={Routes.newsletterUrl} text="Join our guestlist" />
    </FooterSocial>

    <FooterCopyright>
      ©{new Date().getFullYear()}, THE LA ART BOX. All rights reserved{' '}
      <PageTransitionLink to={Routes.privacyPolicy}>
        Privacy policy
      </PageTransitionLink>
    </FooterCopyright>
  </FooterMain>
)

export default Footer
