import styled from 'styled-components'
import { horizontalScale } from '../../styles/utils/conversion.style'
import { font } from '../../styles/vars/font.style'
import { mq } from '../../styles/vars/media-queries.style'

export const LinkBlockMain = styled.a`
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 1.8rem;
  padding-bottom: 1.8rem;
  text-decoration: none;
  border-top: 0.1rem solid currentColor;
  border-bottom: 0;

  ${mq.horizontal} {
    padding-top: ${horizontalScale(18)};
    padding-bottom: ${horizontalScale(18)};
  }
`

export const LinkBlockIcon = styled.span`
  width: 2.6rem;
`

export const LinkBlockText = styled.span`
  padding-top: 0.1em;
  font-family: ${font.secondary};
  font-variation-settings: ${font.weight.secondary.bold};
  font-size: 1.8rem;
  line-height: 1.2;
  text-transform: uppercase;

  ${mq.horizontal} {
    font-size: ${horizontalScale(18)};
  }
`
